.staking {
    background-color: black;
}

.Stakingcontainer {
    background-color: #619b04;
    display: flex;
    font-size: 24px;
    font-family: 'Oswald', sans-serif;
    border: 1px solid white;
    border-radius: 10px;
    flex-wrap: wrap;
    flex-direction: column;
    width: 300px;
    height: 70vh;
    position: relative;
    top: -20px;
}

.staking-box-1 {
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    flex: 1;
    font-family: 'Roboto', sans-serif;
    color: aliceblue;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.staking-box-2 {
    border: 1px solid white;
    flex: 2.5;
    color: white;
    /* background-color: #6615bc; */
    padding: 32px 16px 16px;
}

.staking-box-3 {
    border: 1px solid white;
    /* background-color: #6615bc; */
    flex: 2;
    font-size: 20px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}

#box-3-left {
    margin: 0;
    display: flex;
    flex: 3;
    flex-direction: column;
}

#box-3-right {
    margin: 0;
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.staking-box-4 {
    border: 1px solid white;
    /* background-color: #6615bc; */
    color: white;
    flex: 2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

td {
    width: 200px;
}

#topleft {
    justify-content: top;
    text-align: left;

    font-family: 'Roboto', sans-serif;
}


.topRight {
    justify-content: top;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}


#APR {
    font-size: 16px;
}

.upper {
    flex: 1;
}

.lower {
    flex: 1;
}
/* 
#Approve-btn {
    width: 120px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    
    position: relative;
    flex: 1;
} */

.ToStakeOrNotToStake {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    align-items: center;
    z-index: 0;
}

#stakeMiddle {
    flex:1;
}
#Stake-btn {
    width: 40px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-right: 10px;
}


#Unstake-btn {
    width: 40px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-right: 50px;
}

#Withdraw-btn {
    width: 80px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-left: 20px;
}


.overlay {
    position: absolute;
    display:none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}


.overlay2 {
    position: absolute;
    display:block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}


.overlay3 {
    position: absolute;
    display:none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}


.overlay4 {
    position: absolute;
    display:block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}

.popup {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    text-align: center;
    background: white;
}


.popup2 {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    text-align: center;
    background: white;
}


.popup h3 {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    color: black;
}

.popup2 h3 {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    color: black;
}


.CloseIcon {
    cursor: pointer;
    color: black;
}

#stakeAmountID {
    width: 100px;
}



#GoingToStake {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background-color:black;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-top: 30px;
}

#GoingToUnstake {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background-color:black;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-top: 30px;
}


#maxStake {
    color: black;
    cursor: pointer;
}

#maxStake:hover {    
    color: gray;
}

#maxUnstake {
    color: black;
    cursor: pointer;
}

#maxUnstake:hover {    
    color: gray;
}

#Approve-btn,
#Stake-btn,
#Unstake-btn,
#Withdraw-btn {
    text-align: center;
    justify-content: center;
    border: 1px solid #ffffff;
    border-radius: 40px;
    background: #2ed11f;
    background: -webkit-gradient(linear, left top, left bottom, from(#2ed11f), to(#c7c716));
    background: -moz-linear-gradient(top, #2ed11f, #c7c716);
    background: linear-gradient(to bottom, #2ed11f, #c7c716);
    -webkit-box-shadow: #37d770 0px 0px 40px 0px;
    -moz-box-shadow: #37d770 0px 0px 40px 0px;
    box-shadow: #37d770 0px 0px 40px 0px;
    text-shadow: #175a2f 1px 1px 1px;
    font: normal normal bold 16px arial;
    color: #ffffff;
    text-decoration: none;
}

#Approve-btn:hover,
#Approve-btn:focus,
#Stake-btn:hover,
#Stake-btn:focus,
#Unstake-btn:hover,
#Unstake-btn:focus,
#Withdraw-btn:hover,
#Withdraw-btn:focus{
    border: 0px solid #2eb35d;
    background: rgb(228, 228, 6);
    color: #ffffff;
    text-decoration: none;
}
#Approve-btn:active,
#Stake-btn:active,
#Unstake-btn:active,
#Withdraw-btn:active {
    background: #2c8b24;
    background: -webkit-gradient(linear, left top, left bottom, from(#2c8b24), to(#738f25));
    background: -moz-linear-gradient(top, #2c8b24, #738f25);
    background: linear-gradient(to bottom, #2c8b24, #738f25);
}