.lpstaking {
    background-color: black;
}

.staking-box-1 {
    border: 1px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    flex: 1;
    font-family: 'Roboto', sans-serif;
    color: aliceblue;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.staking-box-2 {
    border: 1px solid white;
    flex: 2.5;
    color: white;
    /* background-color: #6615bc; */
    padding: 32px 16px 16px;
}

.staking-box-3 {
    border: 1px solid white;
    /* background-color: #6615bc; */
    flex: 2;
    font-size: 20px;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}

#box-3-left {
    margin: 0;
    display: flex;
    flex: 3;
    flex-direction: column;
}

#box-3-right {
    margin: 0;
    display: flex;
    flex: 3;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.staking-box-4 {
    border: 1px solid white;
    /* background-color: #6615bc; */
    color: white;
    flex: 2;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

td {
    width: 200px;
}

#topleft {
    justify-content: top;
    text-align: left;

    font-family: 'Roboto', sans-serif;
}


.topRight {
    justify-content: top;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}


#APR {
    font-size: 16px;
}

.upper {
    flex: 1;
}

.lower {
    flex: 1;
}

#Approve-btn {
    width: 120px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    position: relative;
    flex: 1;
}

.ToStakeOrNotToStake {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    align-items: center;
    z-index: 0;
}

#stakeMiddle {
    flex:1;
}
#Stake-btn {
    width: 40px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-right: 10px;
}


#Unstake-btn {
    width: 40px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-right: 50px;
}

#Withdraw-btn {
    width: 80px;
    height: 40px;
    border-color: white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-left: 20px;
}


.overlay {
    position: absolute;
    display:none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}


.overlay2 {
    position: absolute;
    display:block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}


.overlay3 {
    position: absolute;
    display:none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}


.overlay4 {
    position: absolute;
    display:block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10;
    width: 300px;
}

.popup {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    text-align: center;
    background: white;
}


.popup2 {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    text-align: center;
    background: white;
}


.popup h3 {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    color: black;
}

.popup2 h3 {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    color: black;
}


.CloseIcon {
    cursor: pointer;
    color: black;
}

#stakeAmountID {
    width: 100px;
}



#GoingToStake {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background-color:black;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-top: 30px;
}

#GoingToUnstake {
    width: 80px;
    height: 40px;
    border-radius: 10px;
    background-color:black;
    color: white;
    font-family: 'Roboto', sans-serif;
    /* border: #ff00c1; */
    flex:1;
    margin-top: 30px;
}


#maxStake {
    color: black;
    cursor: pointer;
}

#maxStake:hover {    
    color: gray;
}

#maxUnstake {
    color: black;
    cursor: pointer;
}

#maxUnstake:hover {    
    color: gray;
}

#UnstakeAmountID {
    margin-top: 60px;
}